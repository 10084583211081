<template>
  <div v-loading="loading">
    <el-row>
      <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
        <el-form-item label='' prop="resim">
          <el-upload class="resimler" :multiple="true" action="" :limit="2" list-type="picture-card"
                     :file-list="imageListMain" :auto-upload="false" accept=".jpg,.jpeg,.png" :on-preview="firstImage"
                     :on-change="imageUpload" :on-remove="imageRemove">
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
          <!-- <el-button style="margin-top: 15px;width: 150px;" type="primary"
              v-on:click="getGaleriList"
              @click="openDialog" size="mini">
              Sunucudan Seç
          </el-button> -->
        </el-form-item>
      </el-col>
    </el-row>
    <el-dialog title='Sunucudan Fotoğraf Seç' :visible.sync="dialogImgServerVisible" width="60%" append-to-body center
               class="sunucu">
      <el-upload action="#" accept=".jpg,.jpeg,.png" :multiple="false" list-type="picture-card"
                 :file-list="imageListServer" :auto-upload="false">
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{file}">
          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
          <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-delete" @click="addFromServer(file)">
                            <i class="el-icon-plus"></i>
                        </span>
                    </span>
        </div>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
import notification from '../notification'
import EventBus from '@/components/event-bus'
import galeriService from "../WSProvider/GaleriService"
import personelService from "../WSProvider/PersonelService"

import JQuery from 'jquery';

let $ = JQuery;

export default {
  name: "Resim",
  props: ["functionName", "imageList", 'link', 'selectedsFromServer'],
  mounted() {
  },
  watch: {
    imageList: function (val) {
      if (val.length >= 2) {
        $('.resimler .el-upload--picture-card').hide();
      } else {
        $('.resimler .el-upload--picture-card').show();
      }
      this.imageListMain = val
    },
    link: function (val) {
      this.resimLink = val; // resimLink resimleri galeriden silerken gerekli
    }
  },
  data() {
    return {
      dialogImgServerVisible: false,
      loading: false,
      imageListMain: [],
      imagesToSend: [],
      imageListServer: [],
      resimLink: "",
      galeriList: [],
      imagePath: galeriService.ozlukImage,
    }
  },
  methods: {
    openDialog() {
      this.dialogImgServerVisible = true;
      $('.sunucu').find('.el-upload--picture-card').hide();
    },
    firstImage(item) {
      var index = this.imageListMain.indexOf(item);
      var index2 = 0;
      this.imagesToSend.forEach((element, index) => {
        if (element.name === item.name) index2 = index;
      })

      this.imageListMain.splice(index, 1);
      this.imageListMain.unshift(item);

      if (this.imagesToSend.length > 0) {
        var image = this.imagesToSend[index2]
        this.imagesToSend.splice(index2, 1);
        this.imagesToSend.unshift(image);
      }

    },
    imageRemove(file) {
      var result = -1;
      var bool = false;
      //resim eger mevcutsa size özelliği obje içinde gelmiyor. Bu yuzden size değişkeni yoksa sadece sildirme işlemi yapılır.
      this.imageListMain.forEach((el, index) => {
        if (!el.hasOwnProperty("size") && el.name === file.name) {
          bool = true;
          result = index;
          this.removeImageFromGallery(el.id, result);
          // this.removeImageFromGallery(el.tmp_name, result);
        }
      })
      if (!bool) {
        var number = this.imageListMain.findIndex(element => element.name === file.name)
        this.imageListMain.splice(number, 1)
        this.imagesToSend.splice(number, 1)
        EventBus.$emit(this.functionName, true, this.imagesToSend)
      }
    },
    addFromServer(file) {
      console.log('server', file)
      this.selectedsFromServer.push(file.name);
      this.imageListMain.push(file);
      this.dialogImgServerVisible = false;
    },
    imageUpload(file, fileList) {
      if ((file.raw["type"] === 'image/jpg' || file.raw["type"] === 'image/jpeg' || file.raw["type"] === 'image/png')) {
        var item = {
          name: file.name,
          url: file.url,
          id: file.raw.lastModified,
          size: file.raw.size
        };
        this.imageListMain.push(item)
        file.raw["tmp_name"] = file.url; // backend file_url'i tmp_name değişkeninden alıyor
        this.imagesToSend.push(file.raw)
        EventBus.$emit(this.functionName, true, this.imagesToSend, this.imageListMain)
        // EventBus.$emit(this.functionName, true, this.imagesToSend)
        fileList.pop();
      }
    },
    getGaleriList() {
      this.imageListServer = [];
      galeriService.galeriListeleDurumIle(1).then(response => {
        if (response.status === 200) {
          if (response.data.data) {
            response.data.data.forEach(element => {
              var item = {
                tmp_name: element.resim,
                name: element.resim,
                url: this.imagePath + element.resim,
              }
              this.imageListServer.push(item);
            });
            localStorage.setItem("userDataDemirag", response.token)
          }
        }
      }).catch(err => {
        if (err.responseJSON) {
          let error = err.responseJSON
          if (error.status == 401) {
            notification.Status(150, this);
          } else notification.Status("errorMsg", this, error.errMsg)
        } else {
          //notification.Status(503, this)
        }
        this.loading = false;
      });
    },
    removeImageFromGallery(id, index) {
      this.imageListMain.splice(index, 1);
      personelService.personelResimSil(id).then(response => {
        if (response.status === 200) {
          localStorage.setItem("userDataDemirag", response.token)
          notification.Status("success", this, response.msg)
        }
      })
          .catch(err => {
            if (err.responseJSON) {
              let error = err.responseJSON
              if (error.status == 401) {
                notification.Status(150, this);
              } else notification.Status("errorMsg", this, error.errMsg)
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
    }
  }
}
</script>

<style scoped></style>